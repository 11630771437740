<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        <span>ข้อมูลพนักงาน</span>
        <v-spacer></v-spacer>
        <v-btn @click='isAddNewUser = true' color='primary'>เพิ่มผู้พนักงาน</v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        label='ค้นหาพนักงาน'
                        @input='getUserList()'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='shopList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          class='elevation-1'
      >
        <template #[`item.user_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.user_active`]='{ item }'>
          <StatusBlock :status='String(item.user_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='menuList=item.menus;isFixedRole = true'
                  v-on='on'
              >
                <v-icon>{{ mdiTextBoxCheck }}</v-icon>
              </v-btn>
            </template>
            <span>กำหนดสิทธิ์</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='userDetail = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='userDetail = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.user_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.user_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewUser v-model='isAddNewUser' @onAdd='getUserList'/>
    <EditUser v-model='isEdit' :data-edit-user='userDetail' @onUpdate='getUserList'/>
    <SuspendUser v-model='isUpdateStatus' :user-data='userDetail' @onUpdate='getUserList'/>
    <v-dialog v-model="isFixedRole" persistent max-width="400">
      <v-card>
        <v-card-title>กำหนดสิทธิ์</v-card-title>
        <v-divider></v-divider>
        <v-list dense two-line>
          <v-list-item v-for="(item,index) in menuList" :key="index">
            <v-list-item-content class="py-0 my-0">
              <v-list-item-title class="d-flex align-center">
                {{ index + 1 }}) {{ item.menu_group_title }}
                <v-spacer></v-spacer>
                <v-switch
                    v-model="item.map_menu_active"
                    :disabled="index===0"
                    true-value="1"
                    false-value="0"
                    @change="updateRole(index,item)"
                ></v-switch>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" outlined @click="isFixedRole = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>

import {
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiPlus,
  mdiStoreOutline,
  mdiTextBoxCheck,
  mdiCheck,
} from '@mdi/js'
import useUser from './useUser'
import AddNewUser from './AddNewUser'
import EditUser from './EditUser'
import SuspendUser from './SuspendUser'
import FilterStatus from '@/components/FilterStatus'
import StatusBlock from '@/components/StatusBlock'

export default {
  props: {},
  components: {
    StatusBlock,
    FilterStatus,
    AddNewUser,
    EditUser,
    SuspendUser,
  },
  setup() {
    return {
      ...useUser(),
      mdiPencilOutline, mdiDeleteOutline, mdiCheck, mdiTextBoxCheck, mdiPlus
    }
  },

}
</script>

<style scoped>

</style>
