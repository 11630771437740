<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          ช่องทางการขนส่ง
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isAdd  = true">
          เพิ่มช่องทางการขนส่ง
        </v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getTransports'
                        label='ค้นหาช่องทางการขนส่ง'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.transport_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.transport_active`]='{ item }'>
          <StatusBlock :status='String(item.transport_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  :disabled="item.transport_name ==='ไม่ระบุ'"
                  v-bind='attrs'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  :disabled="item.transport_name ==='ไม่ระบุ'"
                  v-bind='attrs'
                  @click='dataEdit = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.transport_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.transport_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewTransport v-model="isAdd" @onAdd="getTransports"/>
    <EditTransport v-model="isEdit" :data-edit="dataEdit" @onUpdate="getTransports"/>
    <SuspendTransport v-model="isUpdateStatus" :data-update="dataEdit" @onUpdate="getTransports"/>
  </div>
</template>

<script>
import useTransport from "./useTransport";
import FilterStatus from "@/components/FilterStatus";
import StatusBlock from "@/components/StatusBlock";
import AddNewTransport from "./AddNewTransport";
import { mdiPencilOutline, mdiDeleteOutline, mdiCheck } from "@mdi/js";
import EditTransport from "./EditTransport";
import SuspendTransport from "./SuspendTransport"

export default {
  props: {},
  components: { EditTransport, AddNewTransport, FilterStatus, StatusBlock, SuspendTransport },
  setup(_, { root, emit }) {
    return {
      ...useTransport(),
      mdiPencilOutline, mdiDeleteOutline, mdiCheck
    }
  },

}
</script>

<style scoped>

</style>
