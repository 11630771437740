<template>
  <div>
    <v-dialog v-model='isEditBank' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มสกุลเงิน
        </v-card-title>
        <v-form ref='formEditBank' @submit.prevent='updateBank'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-select :items="dataBankList"
                        v-model="bankSelection"
                        filled
                        hide-details="auto"
                        label="เลือกธนาคาร"
                        no-data-text="ไม่มีข้อมูล"
                        return-object
                        item-text="bank_name"
                        item-value="bank_code"
              >
                <template slot="item" slot-scope="{item}">
                  <v-list-item-content>
                    <v-list-item-title>
                      ({{ item.bank_code }}) {{ item.bank_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                <template slot="selection" slot-scope="{item}">
                  <v-list-item-content>
                    <v-list-item-title>
                      ({{ item.bank_code }}) {{ item.bank_name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>

            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='bank_full_name' filled dense
                            label='ชื่อบัญชี'
                            placeholder='ชื่อบัญชี'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='bank_number' filled dense
                            label='หมายเลขบัญชี'
                            placeholder='หมายเลขบัญชี'
                            type="number"
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isEditBank`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'
import bankList from "@/fake-db/bankList.json"

export default {
  model: {
    prop: 'isEditBank',
    event: 'update:isEditBank',
  },
  props: {
    isEditBank: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  setup(props, {root, emit}) {
    const bank_full_name = ref('')
    const bank_number = ref('')
    const loading = ref(false)
    const formEditBank = ref(null)
    const dataBankList = ref(bankList)
    const bankSelection = ref(bankList[0].bank_code)

    const updateBank = () => {
      const isFormValid = formEditBank.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/bank',
        param: props.dataEdit.bank_id,
        body: {
          bank_code: bankSelection.value.bank_code,
          bank_name: bankSelection.value.bank_name,
          bank_full_name: bank_full_name.value,
          bank_number: bank_number.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditBank', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isEditBank, value => {
      if (value) {
        loading.value = false
        const dataEditLocal = JSON.parse(JSON.stringify(props.dataEdit))
        bankSelection.value = dataBankList.value.find(item => item.bank_code === dataEditLocal.bank_code)
        bank_full_name.value = dataEditLocal.bank_full_name
        bank_number.value = dataEditLocal.bank_number
      }

    })

    return {
      formEditBank,
      loading,
      bank_full_name,
      bank_number,
      bankSelection,
      dataBankList,
      required,
      updateBank,
    }
  },

}
</script>

<style scoped>

</style>
