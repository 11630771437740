<template>
  <div>
    <v-dialog v-model='isAddNewPromotionDiscount' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มเปอร์เซ็นต์ส่วนลด
        </v-card-title>
        <v-form ref='formAddPromotionDiscount' @submit.prevent='createPromotionDiscount'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='percent_value' filled dense
                            label='เปอร์เซ็นส่วนลด'
                            placeholder='เปอร์เซ็นส่วนลด'
                            hide-details='auto'
                            type="number"
                            :rules='[required]'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewPromotionDiscount`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'
import {mdiCalendarBlank} from "@mdi/js";

export default {
  model: {
    prop: 'isAddNewPromotionDiscount',
    event: 'update:isAddNewPromotionDiscount',
  },
  props: {
    isAddNewPromotionDiscount: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, {root, emit}) {
    const loading = ref(false)
    const formAddPromotionDiscount = ref(null)
    const percent_value = ref('')


    const createPromotionDiscount = () => {
      const isFormValid = formAddPromotionDiscount.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/percent',
        body: {
          percent_value: percent_value.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewPromotionDiscount', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewPromotionDiscount, value => {
      if (value) {
        loading.value = false
        percent_value.value = ''

      }
    })

    return {
      formAddPromotionDiscount,
      loading,
      percent_value,
      mdiCalendarBlank,
      createPromotionDiscount,
      required,
    }
  },

}
</script>

<style scoped>

</style>
