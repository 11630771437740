<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          ข้อมูลส่วนลดแบบเปอร์เซ็นต์
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isAdd  = true">
          เพิ่มส่วนลด
        </v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getDiscounts'
                        label='ค้นหาส่วนลด'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.percent_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.percent_active`]='{ item }'>
          <StatusBlock :status='String(item.percent_active)'/>
        </template>
        <template #[`item.percent_value`]='{ item }'>
          {{ item.percent_value }}%
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.percent_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.percent_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewDiscount v-model="isAdd" @onAdd="getDiscounts"/>
    <EditDiscount v-model="isEdit" :data-edit="dataEdit" @onUpdate="getDiscounts"/>
    <SuspendPromotionDiscount v-model="isUpdateStatus" :data-update="dataEdit" @onUpdate="getDiscounts"/>
  </div>
</template>

<script>
import useDiscount from "./useDiscount";
import FilterStatus from "@/components/FilterStatus";
import StatusBlock from "@/components/StatusBlock";
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck} from "@mdi/js";
import SuspendPromotionDiscount from "./SuspendPromotionDiscount"
import AddNewDiscount from "./AddNewDiscount";
import EditDiscount from "./EditDiscount";

export default {
  props: {},
  components: {EditDiscount, AddNewDiscount, FilterStatus, StatusBlock, SuspendPromotionDiscount},
  setup(_, {root, emit}) {
    return {
      ...useDiscount(),
      mdiPencilOutline, mdiDeleteOutline, mdiCheck
    }
  },

}
</script>

<style scoped>

</style>
