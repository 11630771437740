import {ref, onMounted} from "@vue/composition-api"
import footerDataTables from "@/fake-db/footerDataTables.json";
import {api} from "@/services/api";

export default function useTransport() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'transport_id',
            width: '50',
        },
        {
            text: 'ช่องทางการขนส่ง',
            value: 'transport_name',
            width: '200',
        },
        {
            text: 'ค่าขนส่งพื้นฐาน',
            value: 'transport_price_default',
            width: '120',
            align: 'end',
        },
        {
            text: 'จำนวน/ชิ้น',
            value: 'transport_amount',
            width: '120',
            align: 'end',
        },
        {
            text: 'สถานะ',
            value: 'transport_active',
            width: '120',
            align: 'center',
        },

        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const status = ref('1')

    onMounted(() => {
        getTransports()
    })

    const changeStatus = (id) => {
        status.value = id
        getTransports()
    }

    const getTransports = async () => {
        loading.value = true
        api.get({
            path: '/transports',
            param: `?search=${textSearch.value}&active=${status.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(err => {
            console.log('error : ', err)
            loading.value = false
        })
    }

    return {
        getTransports,
        changeStatus,
        textSearch,
        headers,
        itemsPerPage,
        dataList,
        loading,
        isUpdateStatus,
        dataEdit,
        footer,
        isEdit,
        isAdd,
    }
}