<template>
  <div id="user-view">
    <v-tabs
        v-model="tab"
        show-arrows
        class="user-tabs"
    >
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiCogs }}
        </v-icon>
        <span>ตั้งค่าร้านค้า</span>
      </v-tab>
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiBank }}
        </v-icon>
        <span>บัญชีธนาคาร</span>
      </v-tab>
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiTruckFastOutline }}
        </v-icon>
        <span>ช่องทางการขนส่งอื่น</span>
      </v-tab>
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiCurrencyUsd }}
        </v-icon>
        <span>สกุลเงิน</span>
      </v-tab>
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiSale }}
        </v-icon>
        <span>ส่วนลด</span>
      </v-tab>
      <v-tab>
        <v-icon
            size="20"
            class="me-1"
        >
          {{ mdiAccountMultipleOutline }}
        </v-icon>
        <span>พนักงาน</span>
      </v-tab>
    </v-tabs>
    <v-card class="rounded-tl-0 rounded-tr-0 " :color="tab===0||tab===4?'transparent':''"
            :class="tab===0||tab===4?'elevation-0':''">
      <v-tabs-items v-model="tab" id="user-tabs-content">
        <v-tab-item>
          <ShopSetting :shop-data="shopData" :loading="loading" @onUpdate="getShop"/>
        </v-tab-item>
        <v-tab-item>
          <BankAccount/>
        </v-tab-item>
        <v-tab-item>
          <Transport/>
        </v-tab-item>
        <v-tab-item>
          <Currency/>
        </v-tab-item>
        <v-tab-item>
          <Discount/>
          <Discount2/>
        </v-tab-item>
        <v-tab-item>
          <User/>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiCogs,
  mdiBank,
  mdiTruckFastOutline,
  mdiCurrencyUsd,
  mdiSale,
  mdiAccountMultipleOutline
} from "@mdi/js"
import {ref, onMounted, watch} from "@vue/composition-api"
import ShopSetting from "@/views/manager/settings/ShopSetting";
import {api} from "@/services/api"
import Currency from "@/views/currency/Currency";
import BankAccount from "@/views/bankAccount/BankAccount";
import Transport from "@/views/transport/Transport";
import Discount from "@/views/discount/Discount";
import Discount2 from "@/views/percentDiscount/Discount"
import User from "@/views/manager/users/User.vue";

export default {
  props: {},
  components: {Discount, Transport, BankAccount, ShopSetting, Currency, Discount2, User},
  setup(_, {root, emit}) {
    const tab = ref(0)
    const shopData = ref({})
    const loading = ref(false)
    onMounted(() => {
      getShop()
    })

    const getShop = () => {
      loading.value = true
      api.get({
        path: '/manager/shop',
      }).then(({data}) => {
        loading.value = false
        shopData.value = data
      }).catch(err => {
        loading.value = false
        console.log(err)
      })

    }

    return {
      tab,
      loading,
      mdiCogs,
      getShop,
      mdiTruckFastOutline,
      mdiAccountOutline,
      mdiBank,
      shopData,
      mdiCurrencyUsd,
      mdiSale,
      mdiAccountMultipleOutline
    }
  },

}
</script>

<style scoped lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
