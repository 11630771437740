<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          บัญชีธนาคาร
        </span>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="isAdd = true">
          เพิ่มบัญชีธนาคาร
        </v-btn>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getBanks'
                        label='ค้นหาบัญชีธนาคาร'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
        <v-col cols='12' md='6' lg='4'>
          <FilterStatus @selection='changeStatus'/>
        </v-col>
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.bank_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.bank_active`]='{ item }'>
          <StatusBlock :status='String(item.bank_active)'/>
        </template>
        <template #[`item.bank_name`]='{ item }'>
          <span style="font-family: 'Mitr', sans-serif">
            {{ item.bank_name }}
          </span>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isUpdateStatus = true'
                  v-on='on'
              >
                <v-icon>{{ item.bank_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.bank_active == 1 ? `ระงับ` : `ปกติ` }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewBank v-model="isAdd" @onAdd="getBanks"/>
    <EditBank v-model="isEdit" :data-edit="dataEdit" @onUpdate="getBanks"/>
    <SuspendBank v-model="isUpdateStatus" :data-update="dataEdit" @onUpdate="getBanks"/>
  </div>
</template>

<script>
import useBankAccount from "./useBankAccount"
import FilterStatus from "@/components/FilterStatus"
import AddNewBank from "@/views/bankAccount/AddNewBank";
import { mdiPencilOutline, mdiDeleteOutline, mdiCheck } from '@mdi/js'
import StatusBlock from "@/components/StatusBlock"
import EditBank from "@/views/bankAccount/EditBank";
import SuspendBank from "@/views/bankAccount/SuspendBank";

export default {
  props: {},
  components: { SuspendBank, EditBank, AddNewBank, FilterStatus, StatusBlock },
  setup(_, { root, emit }) {
    return {
      ...useBankAccount(),
      mdiPencilOutline,
      mdiDeleteOutline, mdiCheck
    }
  },

}
</script>

<style scoped>

</style>
