import { ref, onMounted } from "@vue/composition-api"
import footerDataTables from "@/fake-db/footerDataTables.json";
import { api } from "@/services/api";

export default function useCurrency() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'currency_id',
            width: '50',
        },
        {
            text: 'สกุลเงิน (ย่อ)',
            value: 'currency_code',
            width: '120',
        },
        {
            text: 'สกุลเงิน (เต็ม)',
            value: 'currency_text',
            width: '120',
        },
        {
            text: 'Exchange: KRW (วอน)',
            value: 'currency_value',
            width: '120',
            align: 'end',
        },
        {
            text: 'สถานะ',
            value: 'currency_active',
            width: '120',
            align: 'end',
        },

        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const status = ref('1')

    onMounted(() => {
        getCurrencies()
    })

    const changeStatus = (id) => {
        status.value = id
        getCurrencies()
    }

    const getCurrencies = async () => {
        loading.value = true
        api.get({
            path: '/currencys',
            param: `?search=${ textSearch.value }&active=`,
        }).then(({ data }) => {
            dataList.value = data
            loading.value = false
        }).catch(err => {
            console.log('error : ', err)
            loading.value = false
        })
    }

    return {
        getCurrencies,
        changeStatus,
        textSearch,
        headers,
        itemsPerPage,
        dataList,
        loading,
        isUpdateStatus,
        dataEdit,
        footer,
        isEdit,
        isAdd,
    }
}