var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_c('span',[_vm._v(" ข้อมูลสกุลเงิน ")]),_c('v-spacer')],1),_c('v-row',{staticClass:"px-2 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"filled":"","label":"ค้นหาสกุลเงิน","dense":"","hide-details":"","placeholder":"คำค้นหา"},on:{"input":_vm.getCurrencies},model:{value:(_vm.textSearch),callback:function ($$v) {_vm.textSearch=$$v},expression:"textSearch"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.dataList,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"no-data-text":"ไม่มีข้อมูล !","loading-text":"กำลังโหลดข้อมูล...","footer-props":_vm.footer,"no-results-text":"ไม่มีข้อมูล !"},scopedSlots:_vm._u([{key:"item.currency_id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.currency_active",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":String(item.currency_active)}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.dataEdit = item;_vm.isEdit = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v("แก้ไข")])])]}}],null,true)})],1),_c('AddNewCurrency',{on:{"onAdd":_vm.getCurrencies},model:{value:(_vm.isAdd),callback:function ($$v) {_vm.isAdd=$$v},expression:"isAdd"}}),_c('EditCurrency',{attrs:{"data-edit-currency":_vm.dataEdit},on:{"onUpdate":_vm.getCurrencies},model:{value:(_vm.isEdit),callback:function ($$v) {_vm.isEdit=$$v},expression:"isEdit"}}),_c('SuspendCurrency',{attrs:{"data-update":_vm.dataEdit},on:{"onUpdate":_vm.getCurrencies},model:{value:(_vm.isUpdateStatus),callback:function ($$v) {_vm.isUpdateStatus=$$v},expression:"isUpdateStatus"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }