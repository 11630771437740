<template>
  <div>
    <v-dialog v-model='isAddNewsCurrency' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มสกุลเงิน
        </v-card-title>
        <v-form ref='formAddNewsCurrency' @submit.prevent='createCurrency'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='currency_name' filled dense
                            label='สกุลเงิน'
                            placeholder='สกุลเงิน (เต็ม)'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='currency_code' filled dense
                            label='สกุลเงิน (ย่อ)'
                            placeholder='USD,THB,JPY'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='currency_value' filled dense
                            label='ค่าเงินปัจุบัน(ต่อ 1 บาท)'
                            :placeholder='`${currency_code} : 1 บาท`'
                            type="number"
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewsCurrency`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewsCurrency',
    event: 'update:isAddNewsCurrency',
  },
  props: {
    isAddNewsCurrency: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const currency_name = ref('')
    const currency_code = ref('')
    const currency_value = ref(0)
    const loading = ref(false)
    const formAddNewsCurrency = ref(null)

    const createCurrency = () => {
      const isFormValid = formAddNewsCurrency.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/currency',
        body: {
          currency_text: currency_name.value,
          currency_code: currency_code.value,
          currency_value: currency_value.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewsCurrency', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewsCurrency, value => {
      if (value) {
        loading.value = false
        currency_name.value = ''
        currency_code.value = ''
      }
    })

    return {
      formAddNewsCurrency,
      currency_value,
      loading,
      currency_name,
      currency_code,
      required,
      createCurrency,
    }
  },

}
</script>

<style scoped>

</style>
