<template>
  <div>
    <v-card style="height: 100%">
      <v-card-title>
        <span>
          ตั้งค่าร้านค้า
        </span>
      </v-card-title>
      <v-form ref="formUpdateShop" @submit.prevent="updateShop">
        <v-row class="mx-2 my-2">
          <input
              ref='selectImage'
              type='file'
              accept='image/*'
              style='display: none'
              @change='previewFiles'
          />
          <v-col cols="12" md="3" class="d-flex justify-center">
            <v-card flat height="125" max-height="125">
              <v-avatar size="125" class="elevation-4">
                <v-img :src="shopImage"
                       position="relative" top="-10" left="-10"
                       class="rounded-lg elevation-4 rounded-pill ">

                </v-img>
              </v-avatar>
              <v-btn color="primary" class="elevation-10" x-small fab
                     @click="$refs.selectImage.click()"
                     style="position: absolute;right:5%;bottom: 4%; z-index: 99999 !important;">
                <v-icon>{{ mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </v-card>
          </v-col>
          <v-col md="9" cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="shop.shop_id" disabled filled dense label="รหัสร้านค้า"
                              hide-details="auto"
                              placeholder="รหัสร้านค้า"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="shop.shop_name" filled dense label="ชื่อร้านค้า"
                              hide-details="auto"
                              :rules="[required]"
                              placeholder="ชื่อร้านค้า"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="shop.shop_phone" filled dense label="เบอร์โทรศัพท์"
                              hide-details="auto"
                              :rules="[required]"
                              v-mask="['###-####-####']"
                              placeholder="เบอร์โทรศัพท์"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="shop.shop_type" filled dense label="ประเภทร้านค้า"
                              hide-details="auto"
                              placeholder="ประเภทร้านค้า"></v-text-field>
              </v-col>


              <v-col cols='12' md='6' lg="4">
                <v-text-field v-model='shop.shop_product_type'
                              filled dense
                              hide-details='auto'
                              label='ประเภทสินค้าของร้านค้า'
                              placeholder='ประเภทสินค้าของร้านค้า'
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-text-field v-model="shop.shop_branch" filled dense label="สโตร์"
                              hide-details="auto"
                              placeholder="สโตร์"></v-text-field>
              </v-col>
            </v-row>
          </v-col>


          <v-col cols='12' lg="3" md='6'>
            <v-text-field
                v-model='shop.shop_province'
                label='จังหวัด'
                no-data-text='ไม่พบข้อมูล'
                return-object
                placeholder="จังหวัด"
                hide-details='auto'
                dense
                filled
            >
            </v-text-field>
          </v-col>
          <v-col cols='12' lg="3" md='6'>
            <v-text-field
                v-model='shop.shop_district'
                label='อำเภอ'
                no-data-text='ไม่พบข้อมูล'
                return-object
                placeholder="อำเภอ"
                hide-details='auto'
                dense
                filled
            >
            </v-text-field>
          </v-col>
          <v-col cols='12' lg="3" md='6'>
            <v-text-field
                v-model='shop.shop_subdistrict'
                label='ตำบล'
                no-data-text='ไม่พบข้อมูล'
                hide-details='auto'
                return-object
                placeholder=""
                dense
                filled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="shop.shop_address" filled dense label="ที่อยู่"
                          hide-details="auto"
                          :rules="[required]"
                          placeholder="ที่อยู่"></v-text-field>
          </v-col>
          <v-col cols='12' lg="3" md='6'>
            <v-text-field
                v-model='shop.shop_zipcode'
                label='รหัสไปรษณีย์'
                placeholder="รหัสไปรษณีย์"
                hide-details='auto'
                no-data-text='ไม่พบข้อมูล'
                item-text='SEARCH'
                return-object
                dense
                filled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <v-text-field v-model="shop.shop_tax_number" filled dense label="เลขที่ผู้เสียภาษี"
                          hide-details="auto"
                          :rules="[required]"
                          v-mask="['###-###-####']"
                          placeholder="เลขที่ผู้เสียภาษี"></v-text-field>
          </v-col>
          <v-col cols='12' lg="3" md='6'>
            <v-text-field v-model='shop.shop_fax_number' filled dense
                          hide-details='auto'
                          label='เบอร์แฟกซ์'
                          placeholder='เบอร์แฟกซ์'
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="3" class="d-flex align-end justify-end">
            <v-btn color="primary" @click="updateShop" :loading="loadingLocal" :disabled="loadingLocal" type="submit">
              บันทึก
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>

import { ref, onMounted, watch } from "@vue/composition-api"
import { required } from "@core/utils/validation";
import location_new from '@/fake-db/location_new.json'
import { mdiPencilOutline, mdiCogs } from "@mdi/js";
import { api } from "@/services/api";
import store from "@/store";

export default {
  props: {
    shopData: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {},

  setup(props, { root, emit }) {
    const locationList = ref(location_new)
    const dataEditLocal = ref({})
    const shop = ref({})
    const shopImage = ref('@/assets/images/no-image.png')
    const loadingLocal = ref(false)
    const formUpdateShop = ref(null)

    const address = ref({
      SEARCH: '',
      SUB_DISTRICT_NAME: '',
      DISTRICT_NAME: '',
      PROVINCE_NAME: '',
      ZIPCODE: ''
    })
    const previewFiles = event => {
      shop.value.shop_image = event.target.files[event.target.files.length - 1]
      shopImage.value = URL.createObjectURL(event.target.files[event.target.files.length - 1],)
    }

    watch(() => props.loading, value => {
      if (!value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.shopData))
        shop.value = {
          shop_id: dataEditLocal.value.shop_id,
          shop_name: dataEditLocal.value.shop_name,
          shop_phone: dataEditLocal.value.shop_phone,
          shop_subdistrict: dataEditLocal.value.shop_subdistrict,
          shop_province: dataEditLocal.value.shop_province,
          shop_district: dataEditLocal.value.shop_district,
          shop_zipcode: dataEditLocal.value.shop_zipcode,
          shop_address: dataEditLocal.value.shop_address,
          shop_tax_number: dataEditLocal.value.shop_tax_number,
          shop_fax_number: dataEditLocal.value.shop_fax_number,
          shop_branch: dataEditLocal.value.shop_branch,
          shop_type: dataEditLocal.value.shop_type,
          shop_product_type: dataEditLocal.value.shop_product_type,
        }
        shopImage.value = dataEditLocal.value.shop_image
        address.value = {
          SUB_DISTRICT_NAME: dataEditLocal.value.shop_subdistrict,
          DISTRICT_NAME: dataEditLocal.value.shop_district,
          PROVINCE_NAME: dataEditLocal.value.shop_province,
          ZIPCODE: dataEditLocal.value.shop_zipcode,
          SEARCH: `${ dataEditLocal.value.shop_subdistrict } > ${ dataEditLocal.value.shop_district } > ${ dataEditLocal.value.shop_province } > ${ dataEditLocal.value.shop_zipcode }`,
        }
      }
    })

    const updateShop = async () => {
      const isFormValid = await formUpdateShop.value.validate()
      if (!isFormValid) return
      loadingLocal.value = true
      let formData = new FormData()
      formData.append('shop_id', shop.value.shop_id)
      formData.append('shop_name', shop.value.shop_name)
      formData.append('shop_phone', shop.value.shop_phone)
      formData.append('shop_subdistrict', shop.value.shop_subdistrict)
      formData.append('shop_province', shop.value.shop_province)
      formData.append('shop_district', shop.value.shop_district)
      formData.append('shop_zipcode', shop.value.shop_zipcode)
      formData.append('shop_address', shop.value.shop_address)
      formData.append('shop_tax_number', shop.value.shop_tax_number)
      formData.append('shop_fax_number', shop.value.shop_fax_number)
      formData.append('shop_branch', shop.value.shop_branch)
      formData.append('shop_type', shop.value.shop_type)
      formData.append('shop_product_type', shop.value.shop_product_type)
      if (shop.value.shop_image) {
        formData.append('shop_image', shop.value.shop_image)
      }
      api.put({
        path: '/manager/shop',
        body: formData,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
        loadingLocal.value = false
      })
    }

    return {
      locationList,
      required,
      loadingLocal,
      address,
      mdiCogs,
      mdiPencilOutline,
      shop,
      previewFiles,
      updateShop,
      formUpdateShop,
      shopImage
    }
  },

}
</script>

<style scoped>

</style>
