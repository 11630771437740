<template>
  <div>
    <v-card>
      <v-card-title>
        <span>
          ข้อมูลสกุลเงิน
        </span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-row class='px-2 pb-2'>
        <v-col cols='12' md='6' lg='4'>
          <v-text-field v-model='textSearch'
                        filled
                        @input='getCurrencies'
                        label='ค้นหาสกุลเงิน'
                        dense
                        hide-details
                        placeholder='คำค้นหา'></v-text-field>
        </v-col>
<!--        <v-col cols='12' md='6' lg='4'>-->
<!--          <FilterStatus @selection='changeStatus'/>-->
<!--        </v-col>-->
      </v-row>
      <v-data-table
          :headers='headers'
          :items='dataList'
          :items-per-page='itemsPerPage'
          :loading='loading'
          no-data-text='ไม่มีข้อมูล !'
          loading-text='กำลังโหลดข้อมูล...'
          :footer-props='footer'
          no-results-text='ไม่มีข้อมูล !'
          class='elevation-1'
      >
        <template #[`item.currency_id`]='{ index }'>
          {{ index + 1 }}
        </template>
        <template #[`item.currency_active`]='{ item }'>
          <StatusBlock :status='String(item.currency_active)'/>
        </template>
        <template #[`item.actions`]='{ item }'>
          <v-tooltip top>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                  color='primary'
                  icon
                  v-bind='attrs'
                  @click='dataEdit = item;isEdit = true'
                  v-on='on'
              >
                <v-icon>{{ mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>แก้ไข</span>
          </v-tooltip>
<!--          <v-tooltip top>-->
<!--            <template v-slot:activator='{ on, attrs }'>-->
<!--              <v-btn-->
<!--                  color='primary'-->
<!--                  icon-->
<!--                  v-bind='attrs'-->
<!--                  @click='dataEdit = item;isUpdateStatus = true'-->
<!--                  v-on='on'-->
<!--              >-->
<!--                <v-icon>{{ item.currency_active == 1 ? mdiDeleteOutline : mdiCheck }}</v-icon>-->
<!--              </v-btn>-->
<!--            </template>-->
<!--            <span>{{ item.currency_active == 1 ? `ระงับ` : `ปกติ` }}</span>-->
<!--          </v-tooltip>-->
        </template>
      </v-data-table>
    </v-card>
    <AddNewCurrency v-model="isAdd" @onAdd="getCurrencies"/>
    <EditCurrency v-model="isEdit" :data-edit-currency="dataEdit" @onUpdate="getCurrencies"/>
    <SuspendCurrency v-model="isUpdateStatus" :data-update="dataEdit" @onUpdate="getCurrencies"/>
  </div>
</template>

<script>
import useCurrency from "@/views/currency/useCurrency";
import FilterStatus from "@/components/FilterStatus";
import StatusBlock from "@/components/StatusBlock";
import AddNewCurrency from "@/views/currency/AddNewCurrency";
import {mdiPencilOutline, mdiDeleteOutline, mdiCheck} from "@mdi/js";
import EditCurrency from "@/views/currency/EditCurrency";
import SuspendCurrency from "./SuspendCurrency"

export default {
  props: {},
  components: {EditCurrency, AddNewCurrency, FilterStatus, StatusBlock, SuspendCurrency},
  setup(_, {root, emit}) {
    return {
      ...useCurrency(),
      mdiPencilOutline, mdiDeleteOutline, mdiCheck
    }
  },

}
</script>

<style scoped>

</style>
