import {ref, onMounted} from "@vue/composition-api"
import footerDataTables from "@/fake-db/footerDataTables.json";
import {api} from "@/services/api";

export default function useDiscount() {
    const textSearch = ref('')
    const headers = ref([
        {
            text: '#',
            value: 'percent_id',
            width: '50',
        },

        {
            text: 'เปอร์เซ็นส่วนลด',
            value: 'percent_value',
            width: '200',
            align: 'end'
        },
        {
            text: 'สถานะ',
            value: 'percent_active',
            width: '200',
            align: 'center'
        },

        {
            text: 'ตัวเลือก',
            value: 'actions',
            width: '200',
            align: 'center',
        },
    ])
    const itemsPerPage = ref(10)
    const dataList = ref([])
    const loading = ref(false)
    const isUpdateStatus = ref(false)
    const dataEdit = ref({})
    const footer = ref(footerDataTables)
    const isEdit = ref(false)
    const isAdd = ref(false)
    const status = ref('1')

    onMounted(() => {
        getDiscounts()
    })

    const changeStatus = (id) => {
        status.value = id
        getDiscounts()
    }

    const getDiscounts = async () => {
        loading.value = true
        await api.get({
            path: '/percents',
            param: `?search=${textSearch.value}&active=${status.value}`,
        }).then(({data}) => {
            dataList.value = data
            loading.value = false
        }).catch(err => {
            console.log('error : ', err)
            loading.value = false
        })
    }

    return {
        getDiscounts,
        changeStatus,
        textSearch,
        headers,
        itemsPerPage,
        dataList,
        loading,
        isUpdateStatus,
        dataEdit,
        footer,
        isEdit,
        isAdd,
    }
}