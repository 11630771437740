<template>
  <div>
    <v-dialog v-model='isEditCurrency' persistent max-width='500'>
      <v-card>
        <v-card-title>
          แก้ไขสกุลเงิน
        </v-card-title>
        <v-form ref='formEditCurrency' @submit.prevent='updateCurrency'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='currency_name' filled dense
                            label='สกุลเงิน (เต็ม)'
                            placeholder='สกุลเงิน (เต็ม)'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='currency_code' filled dense
                            label='สกุลเงิน (ย่อ)'
                            placeholder='USD,THB,JPY'
                            hide-details='auto'
                            disabled
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='currency_value' filled dense
                            label='ค่าเงินปัจุบัน(ต่อ 1 บาท)'
                            :placeholder='`${currency_code} : 1 บาท`'
                            type="number"
                            hide-details='auto'
                            :disabled='currency_code === "KRW"'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isEditCurrency`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@/@core/utils/validation'
import { api } from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'isEditCurrency',
    event: 'update:isEditCurrency',
  },
  props: {
    isEditCurrency: {
      type: Boolean,
      default: false,
    },
    dataEditCurrency: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const currency_name = ref('')
    const currency_code = ref('')
    const currency_value = ref(0)
    const loading = ref(false)
    const formEditCurrency = ref(null)

    const updateCurrency = () => {
      const isFormValid = formEditCurrency.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/currency',
        param: props.dataEditCurrency.currency_id,
        body: {
          currency_text: currency_name.value,
          currency_code: currency_code.value,
          currency_value: currency_value.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditCurrency', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isEditCurrency, value => {
      if (value) {
        const dataEditLocal = JSON.parse(JSON.stringify(props.dataEditCurrency))
        loading.value = false
        currency_name.value = dataEditLocal.currency_text
        currency_code.value = dataEditLocal.currency_code
        currency_value.value = dataEditLocal.currency_value
      }
    })

    return {
      formEditCurrency,
      loading,
      currency_name,
      currency_value,
      currency_code,
      required,
      updateCurrency,
    }
  },

}
</script>

<style scoped>

</style>
