<template>
  <div>
    <v-dialog v-model='isAddNewsTransport' persistent max-width='500'>
      <v-card>
        <v-card-title>
          เพิ่มช่องทางการขนส่ง
        </v-card-title>
        <v-form ref='formAddNewsTransport' @submit.prevent='createTransport'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='transport_name' filled dense
                            label='ช่องทางการขนส่ง'
                            placeholder='ช่องทางการขนส่ง'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='transport_price_default' filled dense
                            label='ราคาพื้นฐาน'
                            placeholder='ราคาพื้นฐาน'
                            type="number"
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='transport_amount' filled dense
                            label='จำนวน/ชิ้น'
                            placeholder='จำนวน/ชิ้น'
                            type="number"
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddNewsTransport`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'

export default {
  model: {
    prop: 'isAddNewsTransport',
    event: 'update:isAddNewsTransport',
  },
  props: {
    isAddNewsTransport: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  setup(props, {root, emit}) {
    const transport_name = ref('')
    const transport_price_default = ref('')
    const transport_amount = ref(0)
    const loading = ref(false)
    const formAddNewsTransport = ref(null)

    const createTransport = () => {
      const isFormValid = formAddNewsTransport.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.post({
        path: '/transport',
        body: {
          transport_name: transport_name.value,
          transport_price_default: transport_price_default.value,
          transport_amount: transport_amount.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isAddNewsTransport', false)
          emit('onAdd')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isAddNewsTransport, value => {
      if (value) {
        loading.value = false
        transport_name.value = ''
        transport_price_default.value = ''
        transport_amount.value = 0
      }
    })

    return {
      formAddNewsTransport,
      transport_amount,
      loading,
      transport_name,
      transport_price_default,
      required,
      createTransport,
    }
  },

}
</script>

<style scoped>

</style>
