<template>
  <div>
    <v-dialog v-model='isEditCodeDiscount' persistent max-width='500'>
      <v-card>
        <v-card-title>
          แก้ไขโค้ดส่วนลด
        </v-card-title>
        <v-form ref='formEditPromotionDiscount' @submit.prevent='updatePromotionDiscount'>
          <v-row class='mx-2 my-2'>
            <v-col cols='12 '>
              <v-text-field v-model='name_discount' filled dense
                            label='ชื่อส่วนลด'
                            placeholder='ชื่อส่วนลด'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='code_discount' filled dense
                            label='โค้ดส่วนลด'
                            placeholder='โค้ดส่วนลด'
                            hide-details='auto'
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-text-field v-model='percent_discount' filled dense
                            label='จำนวนส่วนลด'
                            placeholder='จำนวนส่วนลด'
                            hide-details='auto'
                            type="number"
                            :rules='[required]'
              ></v-text-field>
            </v-col>
            <v-col cols='12 '>
              <v-dialog
                  ref="dateStart"
                  v-model.trim="isShowDate"
                  :return-value.sync="expire_discount"
                  width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model.trim="expire_discount"
                      label="เลือกวันหมดอายุ"
                      dense
                      filled
                      readonly
                      v-bind="attrs"
                      :rules="[required]"
                      v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model.trim="expire_discount"
                    locale="th"
                    scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dateStart.save(expire_discount)"
                  >
                    ยืนยัน
                  </v-btn>
                  <v-btn
                      text
                      color="secondary"
                      @click="isShowDate = false"
                  >
                    ยกเลิก
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-dialog>
            </v-col>

          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              บันทึก
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isEditCodeDiscount`,false)'>
              ยกเลิก
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ref, watch} from '@vue/composition-api'
import {required} from '@/@core/utils/validation'
import {api} from '@/services/api'
import store from '@/store'
import {mdiCalendarBlank} from "@mdi/js";

export default {
  model: {
    prop: 'isEditCodeDiscount',
    event: 'update:isEditCodeDiscount',
  },
  props: {
    isEditCodeDiscount: {
      type: Boolean,
      default: false,
    },
    dataEditCodeDiscount: {
      type: Object,
      default: () => ({}),
    },

  },
  components: {},
  setup(props, {root, emit}) {
    const loading = ref(false)
    const formEditPromotionDiscount = ref(null)
    const isShowDate = ref(false)
    const expire_discount = ref('')
    const code_discount = ref('')
    const percent_discount = ref('')
    const name_discount = ref('')


    const updatePromotionDiscount = () => {
      const isFormValid = formEditPromotionDiscount.value.validate()
      if (!isFormValid) return
      loading.value = true
      api.put({
        path: '/promotionDiscount',
        param: props.dataEditCodeDiscount.promotion_discount_id,
        body: {
          currency_id: localStorage.getItem('currencyId'),
          promotion_discount_amount: percent_discount.value,
          promotion_discount_code: code_discount.value,
          promotion_discount_name: name_discount.value,
          promotion_discount_expire: expire_discount.value,
        },
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        if (res.response) {
          emit('update:isEditCodeDiscount', false)
          emit('onUpdate')
          loading.value = false
        } else {
          loading.value = false
        }
      }).catch(err => {
        console.log('error : ', err)
      })
    }


    watch(() => props.isEditCodeDiscount, value => {
      if (value) {
        loading.value = false
        const dataEditLocal = JSON.parse(JSON.stringify(props.dataEditCodeDiscount))
        code_discount.value = dataEditLocal.promotion_discount_code
        name_discount.value = dataEditLocal.promotion_discount_name
        percent_discount.value = dataEditLocal.promotion_discount_amount
        expire_discount.value = dataEditLocal.promotion_discount_expire
      }
    })

    return {
      formEditPromotionDiscount,
      loading,
      isShowDate,
      expire_discount,
      code_discount,
      name_discount,
      percent_discount,
      mdiCalendarBlank,
      updatePromotionDiscount,
      required,
    }
  },

}
</script>

<style scoped>

</style>
